module.exports = {
  TYPE: 'production',
  ANALYTICS: {
    ENABLED: true,
    SRC: 'https://statorama.vercel.app/script.js',
    ID: 'bb3b9fc9-ef5c-4e77-b045-15aa44bbd2c7'
  },
  NEWSLETTER: {
    BASE_URL: 'https://vervet.vercel.app',
    PROJECT_ID: '064d2801-6a5b-43b1-9e7a-6e6583ad80d1',
    ID: '4fd8dd4d-50b0-41b9-a0ff-0e5994279298'
  },
  VERVET: {
    BASE_URL: 'https://vervet.vercel.app',
    PROJECTS: {
      MAILER: {
        ID: 'be51fc60-6e82-41cd-8e5a-091cc1fa6380',
        RECIPIENT: `hello@rafaelcamargo.com`
      }
    }
  }
};
